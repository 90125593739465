import { useEffect, useState } from 'react';
import { Outlet, useParams, useNavigate } from 'react-router-dom';

import { MemberType, Member } from '../../api/ceremony';
import provenanceLogoSvg from '../../assets/images/svg/provenange-logo.svg';
import { getCookie, setCookie } from '../../helpers/helper';
import useUser from '../../hooks/useUser';
import useWeddingPlannerStatus from '../../hooks/useWeddingPlannerStatus';
import { useWindowSize } from '../../hooks/useWindowSize';
import { ChooseCollaboratorModal } from '../../pages/Ceremony/CeremonyChecklist/v2/InviteCollaboratorsModal/ChooseCollaboratorModal';
import OrdainedApplicationModal from '../../pages/Ordained/OrdainedApplicationModal';
import OrdainedInfoModal from '../../pages/Ordained/OrdainedInfoModal';
import { useDashboard } from '../../provider/dashboardProvider';
import { usePaywallModal } from '../../provider/paywallModalProvider';
import { AddMemberModal } from '../NewPeopleModal/components/AddMemberModal';
import { PaywallModal } from '../PaywallModal/PaywallModal';
import Footer from '../footer';
import { AccountMenu } from '../v2/AccountMenu/AccountMenu';
import Button from '../v2/Button/Button';
import { Separator } from '../v2/Separator/Separator';

import './pageWrapper.scss';
import { CreateNewEventModals } from './CreateNewEventModals';

import ReferFriends from '../v2/ReferFriend/ReferFriends';

import { MenuContent } from './MenuContent';
import { MobileMenu } from './MobileMenu';

import CampaignItem from '../v2/CampaignItem/CampaignItem';

import diamondWhiteSvg from './images/diamond-white.svg';

import EnterPasswordModal from '../v2/Password/EnterPasswordModal';
import VerifyCodeModal from '../v2/Password/VerifyCodeModal';
import CreateNewPasswordModal from '../v2/Password/CreateNewPasswordModal';
import { usePlanner } from '../../provider/plannerProvider';
import OrdainedSuccessModal from '../../pages/Ordained/OrdainedSuccessModal';

export const PageWrapper = () => {
  const [expanded, setExpanded] = useState(true);
  //This allows for a smooth transition when the menu is expanded/collapsed
  const [shouldExpand, setShouldExpand] = useState(true);

  const params = useParams();
  const size = useWindowSize();

  const { user } = useUser();
  const navigate = useNavigate();

  const {
    ceremony,
    currentUser,
    fetchCeremony,
    fetchGiftcard,
    addModalOpen,
    setAddModalOpen,
    memberType,
    setMemberType,
    memberForEditing,
    setMemberForEditing,
    handleAddMember,
    setIsReferralModalOpen,
  } = useDashboard();

  const { weddingPlannerStatus } = usePlanner();

  const {
    isPaywallModalOpen,
    setIsPaywallModalOpen,
    paywallModalType,
    setPaywallModalType,
    paywallModalReferrer,
    setPaywallModalReferrer,
  } = usePaywallModal();

  useEffect(() => {
    if (params.ceremonyId && !isNaN(parseInt(params.ceremonyId ?? ''))) {
      void fetchCeremony(params.ceremonyId);
      void fetchGiftcard();

      //void fetchAccessibleModulesIdentifiers(params.ceremonyId);
    } else {
      //TODO - what happens here - we don't have a dashboard anymore. Maybe a "Not Found" page
      //navigate('/dashboard');
    }
  }, [params.ceremonyId]);

  const isUserUnsubscribedWP =
    currentUser?.member_type === MemberType.weddingPlanner &&
    !weddingPlannerStatus?.hasActiveSubscription;

  return (
    <div
      className={`PageWrapper-root ${size.isMobile ? 'flex-col' : 'flex-row'}`}
    >
      <CreateNewEventModals />
      {ceremony && (
        <PaywallModal
          isOpen={isPaywallModalOpen}
          onClose={() => setIsPaywallModalOpen(false)}
          type={paywallModalType}
          referrer={paywallModalReferrer}
          ceremonyId={ceremony.id}
        />
      )}

      {memberType && (
        <AddMemberModal
          isOpen={addModalOpen}
          onClose={() => {
            setAddModalOpen(false);
            setMemberForEditing(undefined);
            setMemberType(undefined);
          }}
          onButtonClick={(member: Member) => {
            void handleAddMember(member);
            setAddModalOpen(false);
            setMemberForEditing(undefined);
            setMemberType(undefined);
            const hasReferralModalOpen = getCookie('isReferralModalOpen');
            !hasReferralModalOpen && setIsReferralModalOpen(true);
            !hasReferralModalOpen &&
              setCookie('isReferralModalOpen', 'true', 30);
          }}
          memberType={memberType}
          memberForEditing={memberForEditing}
          currentUser = {currentUser}
        />
      )}

      <ChooseCollaboratorModal />
      <PaywallModal
        isOpen={isPaywallModalOpen}
        onClose={() => setIsPaywallModalOpen(false)}
        type={paywallModalType}
        referrer={paywallModalReferrer}
        ceremonyId={ceremony?.id || 0}
      />
      <EnterPasswordModal />
      <VerifyCodeModal />
      <CreateNewPasswordModal />
      <OrdainedInfoModal />
      <OrdainedApplicationModal />
      <OrdainedSuccessModal />

      {!size.isMobile && (
        <div
          className={`PageWrapper-menu  ${
            size.isMobile ? '' : shouldExpand ? 'menu-expanded' : 'menu-closed'
          }`}
        >
          <div className='PageWrapper-header'>
            <img
              className={`PageWrapper-header-text overflow-hidden h-4 ${
                expanded ? 'w-auto' : 'w-0 '
              }`}
              src={provenanceLogoSvg}
            />

            <Button
              variant='secondary'
              size='100'
              className={`PageWrapper-header-button p-[7px] ${
                expanded ? 'ml-auto' : 'ml-auto mr-4'
              }`}
              onClick={() => {
                if (expanded) {
                  setExpanded(false);
                } else {
                  setTimeout(() => {
                    setExpanded(true);
                  }, 200);
                }

                setShouldExpand((old) => !old);
              }}
            >
              {expanded ? (
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M2 1.75L2 14.25C2 14.6646 2.33281 15 2.70781 15C3.08281 15 3.5 14.6646 3.5 14.25L3.5 1.75C3.5 1.33544 3.16414 1 2.74953 1C2.33492 1 2 1.3375 2 1.75Z' />
                  <path d='M5 7.5C5 7.38281 5.03281 7.15781 5.22031 6.97031L8.96984 3.21975C9.11619 3.07322 9.30809 3 9.5 3C9.89952 3 10.25 3.32161 10.25 3.75C10.25 3.94219 10.175 4.13438 10.0292 4.28016L7.55938 6.75L13.25 6.75C13.6646 6.75 14 7.08544 14 7.5C14 7.91456 13.6646 8.25 13.25 8.25L7.55938 8.25L10.0303 10.7198C10.1768 10.8662 10.25 11.0581 10.25 11.25C10.25 11.6782 9.89952 12 9.5 12C9.30781 12 9.11563 11.925 8.96928 11.7802L5.22022 8.03109C5.03069 7.84214 5 7.61719 5 7.5Z' />
                </svg>
              ) : (
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M14 14.25L14 1.75C14 1.33544 13.6672 1 13.2922 1C12.9172 1 12.5 1.33544 12.5 1.75L12.5 14.25C12.5 14.6646 12.8359 15 13.2505 15C13.6651 15 14 14.6625 14 14.25Z' />
                  <path d='M11 8.5C11 8.61719 10.9672 8.84219 10.7797 9.02969L7.03016 12.7803C6.88381 12.9268 6.69191 13 6.5 13C6.10048 13 5.75 12.6784 5.75 12.25C5.75 12.0578 5.825 11.8656 5.97078 11.7198L8.44062 9.25L2.75 9.25C2.33544 9.25 2 8.91456 2 8.5C2 8.08544 2.33544 7.75 2.75 7.75L8.44062 7.75L5.96975 5.28016C5.82322 5.13381 5.75 4.94191 5.75 4.75C5.75 4.3218 6.10048 4 6.5 4C6.69219 4 6.88437 4.075 7.03072 4.21984L10.7798 7.96891C10.9693 8.15786 11 8.38281 11 8.5Z' />
                </svg>
              )}
            </Button>
          </div>
          <MenuContent expanded={expanded} />

          <Separator
            className='border-forest-100 w-full'
            variant='horizontal'
          />

          <div className='w-full pt-4 bottom-0'>
            {isUserUnsubscribedWP && (
              <Button
                size='100'
                fullWidth={expanded}
                onClick={() => navigate('/wp-pay-plans/')}
                className={expanded ? 'mb-2' : 'm-auto mb-2 block'}
              >
                <>
                  <img
                    src={diamondWhiteSvg}
                    className={expanded ? 'inline mr-2' : ''}
                    alt='upgrade to pro'
                  />
                  {expanded ? 'Upgrade to Pro' : null}
                </>
              </Button>
            )}
            <div className='mb-2'>
              <ReferFriends expanded={expanded} />
            </div>
            <div>
              <AccountMenu
                name={user ? `${user.first_name} ${user.last_name}` : ''}
                expanded={expanded}
              />
            </div>
          </div>
        </div>
      )}

      <div
        className={`PageWrapper-content flex flex-col justify-top items-center h-full w-full  min-h-screen ${
          size.isMobile ? '' : expanded ? 'menu-expanded' : 'menu-closed'
        }`}
      >
        {size.isMobile && (
          <MobileMenu
            name={user ? `${user.first_name} ${user.last_name}` : ''}
          />
        )}
        <div className='mt-[62px] md:mt-0 flex flex-1 flex-col items-stretch p-2 sm:p-6 lg:p-8 max-w-[1100px] w-full'>
          <>
            <CampaignItem
              regularComponent={<></>}
              renderCampaignComponent={(discountPercentage, couponCode) => (
                <div
                  onClick={() => {
                    setPaywallModalReferrer('campaign');
                    setPaywallModalType('ceremony');
                    setIsPaywallModalOpen(true);
                  }}
                  className='w-full px-1 md:px-2 py-1 md:py-2 text-center bg-copper-primary text-white mb-2 cursor-pointer underline-offset-4'
                >
                  {`GET ${discountPercentage}% OFF | USE CODE: `}
                  <u>{couponCode}</u>
                  {` | OFFER ENDS SOON!`}
                </div>
              )}
            />
            <Outlet />
          </>
        </div>
        <Footer />
      </div>
    </div>
  );
};
