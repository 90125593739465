import { Collapse } from '@mui/material';
import classNames from 'classnames';
import React, { useState } from 'react';
import { IoChevronDownOutline, IoChevronForwardOutline } from 'react-icons/io5';

import { Separator } from '../../../../components/v2/Separator/Separator';
import { Typography } from '../../../../components/v2/Typography/Typography';
import { useWindowSize } from '../../../../hooks/useWindowSize';

import ChecklistItemComponent, { ChecklistItemStatus } from './ChecklistItem';

export enum ChecklistGroups {
  THREE_MONTHS_BEFORE = 'three_months_before',
  TWO_MONTHS_BEFORE = 'two_months_before',
  ONE_MONTH_BEFORE = 'one_month_before',
  ONE_WEEK_BEFORE = 'one_week_before',
  AFTER_THE_WEDDING = 'after_the_wedding',
  WEDDING_PLANNER = 'wp_checklist_group_1',
  GUEST = 'guest_checklist_group_1',
}

export type GroupChecklistItem = {
  id?: number;
  title: string;
  iconBackground: string;
  iconSrc: string;
  onClick: () => void;
  status: ChecklistItemStatus;
  showGem?: boolean;
  onSkipTaskClick?: () => void;
  onMarkCompleteClick?: () => void;
  onWatchDemoClick?: () => void;
  onAfterCompletedClick?: () => void;
  ordainedButtonText?: string;
  paidForOrdination?: boolean;
};



type ChecklistGroupProps = {
  title: string;
  checklistItems: GroupChecklistItem[];
  endTimelineInLastItem?: boolean;
};

const ChecklistGroup = (props: ChecklistGroupProps) => {
  const { checklistItems, title, endTimelineInLastItem } = props;
  const [expanded, setExpanded] = useState(true);
  const { isMobile } = useWindowSize();

  const timelineCheckPoint = (className?: string) => (
    <div
      className={`h-6 w-6 rounded-full bg-forest-100 relative ${
        className || ''
      } `}
    >
      <div className='h-3 w-3 absolute top-[6px] right-[6px] rounded-full bg-primary-primary' />
    </div>
  );

  return (
    <div className='mt-2'>
      <div className='flex flex-row gap-3 w-full justify-between'>
        <div className='flex flex-row items-center'>
          {!isMobile && timelineCheckPoint('mr-4')}

          {checklistItems.length ? (
            <div className='mr-2' onClick={() => setExpanded((old) => !old)}>
              {expanded ? (
                <IoChevronDownOutline />
              ) : (
                <IoChevronForwardOutline />
              )}
            </div>
          ) : null}

          <Typography type='display-50'>{title}</Typography>
        </div>
      </div>

      {checklistItems.length ? (
        <div className='flex align-stretch'>
          {!isMobile && (
            <div className='flex flex-col align-stretch'>
              <Separator
                variant='vertical'
                className='border-forest-100 border-2 w-1 mx-[10px] mt-2 grow'
              />

              {endTimelineInLastItem && expanded && (
                <div className='mb-10 mt-1'>{timelineCheckPoint('mt-1')}</div>
              )}
            </div>
          )}

          <Collapse
            className={classNames('w-full py-4', {
              'ml-4': !isMobile,
            })}
            in={expanded}
            unmountOnExit
          >
            {checklistItems.map((checklistItem, index) => (
              <ChecklistItemComponent
                onClick={checklistItem.onClick}
                iconBackground={checklistItem.iconBackground}
                iconSrc={checklistItem.iconSrc}
                title={checklistItem.title}
                status={checklistItem.status}
                showGem={checklistItem.showGem}
                onSkipTaskClick={checklistItem.onSkipTaskClick}
                onMarkCompleteClick={checklistItem.onMarkCompleteClick}
                onWatchDemoClick={checklistItem.onWatchDemoClick}
                onAfterCompletedClick={checklistItem.onAfterCompletedClick}
                key={index}
                ordainedButtonText={checklistItem.ordainedButtonText}
                paidForOrdination={checklistItem.paidForOrdination}
              />
            ))}
          </Collapse>
        </div>
      ) : null}
    </div>
  );
};

export default ChecklistGroup;
