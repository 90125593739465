import classNames from 'classnames';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MemberType } from '../../api/ceremony';
import checkMarkGreen from '../../assets/images/icons/checkmark-green.png';
import elipsisSvg from '../../assets/images/icons/elipsis.svg';
import vowsSvg from '../../assets/images/image/vows.svg';
import evelopeSvg from '../../assets/images/svg/envelope.svg';
import { getMemberTypeTitle, getRoleTitle } from '../../helpers/dropdownHelper';
import { useWindowSize } from '../../hooks/useWindowSize';
import { Drawer } from '../../pages/ModulesV2/components/Drawer/Drawer';
import Button from '../v2/Button/Button';
import ButtonPill from '../v2/ButtonPill';
import { Typography } from '../v2/Typography/Typography';

import './GuestSpeakerCard.scss';
import { GuestSpeakerCardProps } from './typings.d';

import { Menu, MenuItem } from '@mui/material';

export const GuestSpeakerCard = ({
  fullName,
  role,
  email,
  onRemoveClick,
  allotedTime,
  speakingEvent,
  onManageInviteClick,
  onViewToastClick,
  inviteAccepted,
  onOpenNote,
  draggingHandle,
  showImage = false,
}: GuestSpeakerCardProps) => {
  const { isMobile, isTablet } = useWindowSize();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const toggleShowOptions = (e?: React.MouseEvent<HTMLElement>) => {
    if (isMobile) setDrawerOpen(!drawerOpen);
    else setMenuOpen(!menuOpen);

    e ? setAnchorEl(e.currentTarget) : null;
  };

  const onManageInviteClickFn = () => {
    toggleShowOptions();
    onManageInviteClick ? onManageInviteClick() : null;
  };

  const onRemoveClickFn = () => {
    toggleShowOptions();
    onRemoveClick ? onRemoveClick() : null;
  };

  const onViewToastClickFn = () => {
    toggleShowOptions();
    onViewToastClick ? onViewToastClick() : null;
  };

  return (
    <div className={classNames('GuestSpeakerCard', {})}>
      <div
        className={classNames('flex items-center', {
          'm-4': isMobile || isTablet,
        })}
      >
        {draggingHandle || null}
        {!isMobile && showImage ? (
          <img className='inline' src={vowsSvg} />
        ) : null}
        <div className='inline-flex flex-col items-start'>
          <Typography type='display-50'>{fullName + "'s Toast"}</Typography>
          <Typography
            type='body-100'
            variant='functional-low'
            className='text-gray-500'
          >
            {email}
          </Typography>

          <div className='flex items-center mt-1'>
            {
              <div className='rounded-lg border border-solid border-emerald p-1 mt-2'>
                <Typography type='body-100' variant='functional-low'>
                  {inviteAccepted ? (
                    <div className='flex items-center gap-1'>
                      Invite Accepted
                      <img src={checkMarkGreen} />
                    </div>
                  ) : (
                    'Invite Sent'
                  )}
                </Typography>
              </div>
            }
            {onOpenNote ? (
              <Button variant='text' onClick={onOpenNote} className='ml-4'>
                <>
                  <img src={evelopeSvg} className='mr-2 w-[16px] inline' />
                  <Typography
                    type='body-100'
                    variant='functional-low'
                    className='inline'
                  >
                    View Note
                  </Typography>
                </>
              </Button>
            ) : null}
          </div>
        </div>

        <div className='flex justify-end'>
          <img
            className='cursor-pointer absolute right-2 top-4'
            onClick={(e) => toggleShowOptions(e)}
            src={elipsisSvg}
          />
        </div>

        <Drawer open={drawerOpen} onClose={() => toggleShowOptions()}>
          <MenuItem onClick={onManageInviteClick}>Manage Invite</MenuItem>
          <MenuItem onClick={onRemoveClick}>Remove</MenuItem>
          <MenuItem onClick={onViewToastClickFn}>Preview Toast</MenuItem>
          <ButtonPill
            className='w-full mt-2'
            onClick={toggleShowOptions}
            text='Cancel'
          />
        </Drawer>
        <Menu
          PaperProps={{
            style: {
              backgroundColor: '#EFEDE7',
              boxShadow:
                '0px 1px 8px rgba(53, 71, 64, 0.16), 0px 2px 3px rgba(53, 71, 64, 0.08)',
              borderRadius: 0,
              borderWidth: '1px',
              borderColor: '#9AA3A0',
            },
          }}
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={() => toggleShowOptions()}
        >
          <MenuItem onClick={onManageInviteClickFn}>Manage Invite</MenuItem>
          <MenuItem onClick={onRemoveClickFn}>Remove</MenuItem>
          <MenuItem onClick={onViewToastClickFn}>Preview Toast</MenuItem>
        </Menu>
      </div>

      {!(isMobile || isTablet) || isOpen ? (
        <div
          className={classNames('flex flex-wrap justify-between', {
            'no-wrap justify-start': !(isMobile || isTablet),
            'mx-4': isMobile || isTablet,
          })}
        >
          {role && fullName !== 'Partner' ? (
            <div className='grey-container association-box'>
              <Typography className='text-center' type='body-600'>
                {Object.values(MemberType).includes(
                  MemberType[role as keyof typeof MemberType]
                )
                  ? getMemberTypeTitle(role)
                  : getRoleTitle(role)}
              </Typography>
              <Typography
                className='text-center text-emerald'
                type='body-200'
                variant='functional-low'
              >
                Association
              </Typography>
            </div>
          ) : null}

          {speakingEvent ? (
            <div className='grey-container event-box'>
              <Typography className='text-center' type='body-600'>
                {speakingEvent}
              </Typography>
              <Typography
                className='text-center text-emerald'
                type='body-200'
                variant='functional-low'
              >
                Speaking Event
              </Typography>
            </div>
          ) : null}

          {allotedTime ? (
            <div className='grey-container length-box'>
              <Typography className='text-center' type='body-600'>
                {allotedTime.toString() + ' mins'}
              </Typography>
              <Typography
                className='text-center text-emerald'
                type='body-200'
                variant='functional-low'
              >
                Toast Length
              </Typography>
            </div>
          ) : null}
        </div>
      ) : null}
      {(isMobile || isTablet) && (
        <div
          className='flex justify-center p-4'
          style={{ backgroundColor: '#E2E0DB' }}
        >
          <Button variant='text' onClick={() => setIsOpen(!isOpen)}>
            {!isOpen ? 'Show Details' : 'Hide Details'}
          </Button>
        </div>
      )}
    </div>
  );
};
