import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';

import { Ceremony, Member } from '../../../api/ceremony';
import { saveAnswer } from '../../../api/question';
import QuestionSaveStatus from '../../../components/QuestionSave';
import Button from '../../../components/button';
import { OptionButton } from '../../../components/forms/CustomRadioSelect';
import CustomTextInput from '../../../components/forms/CustomTextInput';
import { replacePlaceholders } from '../../../helpers/placeholderHelper';
import usePronouncements from '../../../hooks/usePronouncements';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useActiveQuestion } from '../../../provider/activeQuestionProvider';
import {
  Question,
  QuestionAnswer,
} from '../common/moduleQuestion/ModuleQuestion';
import { Tip } from '../common/tip/Tip';

import { PronouncementsModal } from './PronouncementsModal';

type QuestionProps = {
  question: Question;
  setQuestionChanged: (value: boolean) => void;
  couple1?: Member;
  couple2?: Member;
  officiant?: Member;
  currentUser?: Member;
  ceremony?: Ceremony;
  showTip?: boolean;
  readOnly?: boolean;
  asterisk?: boolean;
};

export const PronouncementsQuestion = (props: QuestionProps) => {
  const {
    question,
    ceremony,
    showTip = true,
    couple1,
    couple2,
    officiant,
    currentUser,
    readOnly,
    setQuestionChanged,
    asterisk,
  } = props;
  const [tipExpanded, setTipExpanded] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [readonlyModalOpen, setReadonlyModalOpen] = useState(false);

  const { pronouncements } = usePronouncements();
  const { activeQuestionId, setActiveQuestionId } = useActiveQuestion();

  const [title, setTitle] = useState<string>('Save Changes');
  const [changed, setChanged] = useState<boolean>(false);
  const [optionId, setOptionId] = useState<string>();
  const [textAnswer, setTextAnswer] = useState<string>();
  useEffect(() => {
    if (question && question.answers) {
      const answer = question.answers[0];
      if (answer) {
        setOptionId(answer.option_id);
        setTextAnswer(answer.text_answer);
      }
    }
  }, [question]);

  const handleChange = (pronouncementId: number) => {
    setChanged(true);
    setModalOpen(false);
    setTitle('Save Changes');
    setOptionId(pronouncementId.toString());
  };

  const handleTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setChanged(true);
    setTitle('Save Changes');
    setTextAnswer(ev.target.value);
  };

  const { openSnackBar } = useSnackbar();

  const handleSave = async (
    question: Question,
    ceremony?: Ceremony,
    optionId?: string,
    textAnswer?: string
  ) => {
    if (ceremony) {
      const answer: QuestionAnswer = {
        ceremony_id: ceremony.id,
        question_id: question.id,
        option_id: optionId,
        text_answer: textAnswer,
      };
      setChanged(false);
      setTitle('Saving');
      try {
        const saveResponse = await saveAnswer(answer);
        if (saveResponse.success) {
          setTitle('Saved');
          setQuestionChanged(true);
        } else {
          setChanged(true);
          openSnackBar(saveResponse.message, 5000, 'error');
          setTitle('Save Changes');
        }
      } catch (error) {
        setChanged(true);
        openSnackBar('Unexpected error', 5000, 'error');
        setTitle('Save Changes');
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCall = useCallback(
    debounce(
      (
        question: Question,
        ceremony?: Ceremony,
        optionId?: string,
        textAnswer?: string
      ) => {
        void handleSave(question, ceremony, optionId, textAnswer);
      },
      2000
    ),
    []
  );

  useEffect(() => {
    if (changed === true) {
      debouncedCall(question, ceremony, optionId, textAnswer);
    }
  }, [ceremony, question, optionId, textAnswer, changed, debouncedCall]);

  useEffect(() => {
    if (activeQuestionId && activeQuestionId !== question.id) {
      setTipExpanded(0);
    } else if (activeQuestionId && activeQuestionId === question.id) {
      setTipExpanded(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestionId]);

  return pronouncements ? (
    <>
      <PronouncementsModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        pronouncements={pronouncements}
        onUsePronouncementClick={handleChange}
      />
      <PronouncementsModal
        isOpen={readonlyModalOpen}
        onClose={() => {
          setReadonlyModalOpen(false);
        }}
        pronouncements={pronouncements.filter(
          (d) => d.id.toString() === optionId
        )}
      />

      <div className='col-span-4 md:col-span-6 lg:col-span-12 grid grid-cols-4 md:grid-cols-6 lg:grid-cols-12'>
        <div
          onClick={() => setActiveQuestionId(question.id)}
          className='question-container mb-8 px-4 md:px-11 py-6 border col-span-4 md:col-span-6 lg:col-span-9'
        >
          <div className='mx-2 my-3 font-nanum text-2xl leading-8'>
            {replacePlaceholders(
              question.question,
              couple1?.preferred_name || couple1?.legal_name,
              couple2?.preferred_name || couple2?.legal_name,
              officiant?.preferred_name || officiant?.legal_name,
              currentUser?.preferred_name || currentUser?.legal_name
            )}
            {asterisk ? (
              <span className='ml-1' style={{ color: '#995D30' }}>
                *
              </span>
            ) : null}
          </div>
          <div className='mx-2 w-full'>
            <OptionButton
              onClick={() => !readOnly && setModalOpen(true)}
              label={'Show us the options'}
            />
            {optionId && !readOnly && (
              <div className='mt-3 w-full'>
                <OptionButton
                  onClick={() => !readOnly && setReadonlyModalOpen(true)}
                  selected={true}
                  label={`${
                    pronouncements
                      ? pronouncements.find((d) => d.id.toString() === optionId)
                          ?.name || ''
                      : ''
                  }`}
                />
              </div>
            )}
          </div>

          <CustomTextInput
            className='mt-4'
            multiline
            rows={5}
            value={textAnswer}
            onChange={handleTextChange}
            placeholder={question.textfield_placeholder}
            disabled={optionId === ''}
            readOnly={readOnly}
          />
          {(textAnswer || optionId) && (
            <QuestionSaveStatus
              disabled={!changed}
              title={title}
              onClick={() =>
                handleSave(question, ceremony, optionId, textAnswer)
              }
            ></QuestionSaveStatus>
          )}
        </div>
        {showTip && (
          <Tip
            text={replacePlaceholders(
              question.help_short_text,
              couple1?.preferred_name || couple1?.legal_name,
              couple2?.preferred_name || couple2?.legal_name,
              officiant?.preferred_name || officiant?.legal_name,
              currentUser?.preferred_name || currentUser?.legal_name
            )}
            id={1}
            expanded={tipExpanded === 1}
            setExpanded={(id: number) => setTipExpanded(id)}
            className={'w-72 ml-5 bg-forest-primary'}
            link={question.help_detail_text}
          />
        )}
      </div>
    </>
  ) : (
    <></>
  );
};
