import { Order } from '../../api/paywall';

export const getOrdainedButtonText = (orders: Order[]) => {
  const isCertificatePurchased = orders.some(
    (order) => order.is_ordination_order && order.status === 'confirmed'
  );
  return isCertificatePurchased ? 'Download Certificate' : 'Claim Certificate';
};

export const didPayForOrdination = (orders: Order[]) => {

  return orders.some(
    (order) => order.is_ordination_order && order.status === 'confirmed'
  );
}
