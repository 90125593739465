import { Label } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import { createElement, useEffect, useRef, useState } from 'react';

import { Member, MemberType } from '../../../api/ceremony';
import { validateEmail } from '../../../api/emailValidation';
import checkmark from '../../../assets/images/icons/Checkmark-green.svg';
import { getMemberTypeTitle } from '../../../helpers/dropdownHelper';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useSnackbar } from '../../../provider/snackbarProvider';
import { NewButton } from '../../NewButton';
import CustomTextInput from '../../forms/CustomTextInput';
import Button from '../../v2/Button/Button';
import { Checkbox } from '../../v2/Checkbox';
import InfoWarning from '../../v2/InfoWarning/InfoWarning';
import { Modal } from '../../v2/Modal/Modal';
import PreviewEmail from '../../v2/PreviewEmail/PreviewEmail';
import { ARTICLE_TYPES } from '../../v2/PreviewEmail/data/elevioArticles';
import { SelectField } from '../../v2/SelectField';
import { TextField } from '../../v2/TextField';
import { Typography } from '../../v2/Typography/Typography';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onButtonClick: (member: Member) => void;
  memberType: MemberType;
  memberForEditing?: Member;
  currentUser? : Member;
};
export const AddMemberModal = (props: Props) => {
  const { couple1, couple2 } = useDashboard();

  const { isOpen, onClose, onButtonClick, memberType, memberForEditing, currentUser } =
    props;
  

  const [inviteLinkCopied, setInviteLinkCopied] = useState(false);
  const [member, setMember] = useState({} as Member);
  const [roleNeedsUpdate, setRoleNeedsUpdate] = useState(false);
  const [memberRole, setMemberRole] = useState<MemberType>();
  const [memberInvited, setMemberInvited] = useState(false);
  const [elevioArticleType, setElevioArticleType] = useState<
    ARTICLE_TYPES | undefined
  >(undefined);
  const [emailError, setEmailError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');

  const checkIsEmailValid = async (email: string) => {
    const response = await validateEmail(email);
    if (response.success) {
      return response.data.isValid;
    }
    return false;
  };

  const handleButtonClick = async (member: Member) => {
    let hasErrors = false;
    if (!firstName) {
      setFirstNameError('Please enter first name');
      hasErrors = true;
    }
    if (!lastName) {
      setLastNameError('Please enter last name');
      hasErrors = true;
    }
    if (!member.email) {
      setEmailError('Please enter an email address');
      hasErrors = true;
    } else {
      //FE email validation - triggers only when email is not empty
      let isEmailValid = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
        member.email
      );
      if (isEmailValid) {
        //BE email validation - triggers only when FE validation is passed
        isEmailValid = await checkIsEmailValid(member.email);
        if (!isEmailValid) {
          setEmailError('Please enter a valid email address');
          hasErrors = true;
        } else {
          setEmailError('');
        }
      } else {
        setEmailError('Please enter a valid email address');
        hasErrors = true;
      }
    }
    if (!hasErrors) {
      onButtonClick(member);
    }
  };

  const myRefname = useRef<HTMLInputElement>(null);
  const closeModalOpeningElevio: () => void = () => {
    onClose();
    if (myRefname && myRefname.current) myRefname.current.click();
  };

  useEffect(() => {
    if (memberType === MemberType.officiant) {
      setElevioArticleType('INVITE_OFFICIANT');
    }

    if (memberForEditing?.id === couple1?.id) {
      setElevioArticleType('INVITE_PARTNER_A');
    }

    if (memberForEditing?.id === couple2?.id) {
      setElevioArticleType('INVITE_PARTNER_B');
    }

    if (memberType === MemberType.weddingPlanner) {
      setElevioArticleType('INVITE_PLANNER');
    }
  }, [memberForEditing, memberType]);

  useEffect(() => {
    if (memberForEditing) {
      setMember(memberForEditing);
      memberForEditing.legal_name?.includes('TBD')
        ? setRoleNeedsUpdate(true)
        : setMemberRole(memberForEditing.member_type);
      if (memberForEditing.legal_name) {
        setFirstName(memberForEditing.legal_name.split(' ')[0]);
        setLastName(memberForEditing.legal_name.split(' ')[1]);
      }
    }

    if (
      !memberForEditing ||
      !memberForEditing.legal_name ||
      !memberForEditing.legal_name?.includes('TBD')
    ) {
      setMember((old) => ({ ...old, member_type: memberType }));
      setMemberRole(memberType);
    }
    if (memberForEditing && memberForEditing.email) setMemberInvited(true);
  }, [memberForEditing, memberType]);

  const memberAcceptedInvite = member.user_id != undefined;

  const updateFormField = (property: string | number, value: string) => {
    const updated = { ...member };
    switch (property) {
      case 'legal_name': {
        updated.legal_name = value;
        break;
      }
      case 'first_name': {
        updated.legal_name = `${value} ${
          updated.legal_name?.split(' ')[1] || ''
        }`;
        updated.preferred_name = value;
        setFirstName(value);
        if (value) {
          setFirstNameError('');
        }
        break;
      }
      case 'last_name': {
        updated.legal_name = `${
          updated.legal_name?.split(' ')[0] || ''
        } ${value}`;
        setLastName(value);
        if (value) {
          setLastNameError('');
        }
        break;
      }
      case 'email': {
        updated.email = value;
        setEmailError('');
        break;
      }
      case 'member_type': {
        updated.member_type = value as MemberType;
        setMemberRole(value as MemberType);
        setRoleNeedsUpdate(false);
        break;
      }
    }
    // updated.member_type = property !== 'member_type' ? memberType : updated.member_type;

    setMember({ ...updated });
  };

  let mainButtonText = '';

  if (memberAcceptedInvite) mainButtonText = 'Back to Dashboard';
  else if (memberForEditing && memberForEditing.email)
    mainButtonText = 'Resend Invite';
  else mainButtonText = 'Send Invite';

  const copyInviteLink = () => {
    if ( !currentUser?.ceremony_id || !memberForEditing?.email) return;

    const roleNumber = 
      memberType === MemberType.bride ||
      memberType === MemberType.other ||
      memberType === MemberType.groom ? '1' : 
      memberType === MemberType.officiant ? '2' :
      memberType === MemberType.guests ? '3' :
      memberType === MemberType.weddingPlanner ? '4' : '3';

    const base64Email = btoa(memberForEditing.email);
    const base64ceremonyId = btoa(currentUser.ceremony_id.toString());

    const inviteLink = window.location.origin + `/review?r=${roleNumber}&e=${base64Email}&cid=${base64ceremonyId}`;

    navigator.clipboard.writeText(inviteLink);

    setInviteLinkCopied(true);

    setTimeout(() => {
      setInviteLinkCopied(false);
    }, 3000);
  }

  const getInviteButtonText = () => {
    if (inviteLinkCopied) return <>Link Copied <img src={checkmark} className='inline ml-1' alt='checkmark'/></>;
    else if (
      memberType === MemberType.bride || 
      memberType === MemberType.groom || 
      memberType === MemberType.other ) return 'Copy Invite Link for Partner';
    else if (memberType === MemberType.officiant) return 'Copy Invite Link for Officiant';
    else if (memberType === MemberType.guests) return 'Copy Invite Link for Guests';
    else if (memberType === MemberType.weddingPlanner) return 'Copy Invite Link for Wedding Planner';
    return 'Copy Invite Link';
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='NewPeopleModal'>
        <Typography type='display-100'>
          {`${memberForEditing && memberForEditing.email ? 'Edit' : 'Add'} ${
            memberForEditing?.legal_name === 'TBD TBD'
              ? 'Partner'
              : getMemberTypeTitle(memberType)
          }`}
        </Typography>

        {memberForEditing ? (
          memberAcceptedInvite ? (
            <InfoWarning
              text={
                <>
                  This person has already accepted your invite and you can no
                  longer update their info as that is now handled through their
                  account settings. If you wish to remove this person from this
                  please
                  <span
                    onClick={() => closeModalOpeningElevio()}
                    className='ml-1 underline cursor-pointer'
                  >
                    contact support
                  </span>{' '}
                  <div
                    ref={myRefname}
                    className='hidden contact-support-elevio'
                  ></div>
                </>
              }
              variant='grey'
            />
          ) : (
            memberInvited && (
              <InfoWarning
                text='Pending: You already sent an invite but it hasn’t been accepted yet. You can resend it to the same email address or update it and send it to a different one.'
                variant='yellow'
              />
            )
          )
        ) : null}

        <div>
          <CustomTextInput
            label='First name'
            placeholder='Name'
            size={200}
            value={
              member.legal_name?.split(' ')[0].includes('TBD')
                ? ''
                : member.legal_name?.split(' ')[0] || ''
            }
            onChange={(e) => {
              updateFormField('first_name', e.target.value);
            }}
            disabled={memberAcceptedInvite}
            error={!!firstNameError}
            helperText={firstNameError}
          />

          <CustomTextInput
            label='Last name'
            placeholder='Name'
            size={200}
            value={
              member.legal_name?.split(' ')[1] &&
              member.legal_name?.split(' ')[1].includes('TBD')
                ? ''
                : member.legal_name?.split(' ')[1] || ''
            }
            onChange={(e) => {
              updateFormField('last_name', e.target.value);
            }}
            disabled={memberAcceptedInvite}
            error={!!lastNameError}
            helperText={lastNameError}
          />
          {memberForEditing?.legal_name === 'TBD TBD' && (
            <SelectField
              className='min-w-[200px]'
              label='Role'
              labelVariant='outside'
              onChange={(newValue) => {
                updateFormField('member_type', newValue as string);
              }}
              value={memberRole}
              options={[
                {
                  label: getMemberTypeTitle(MemberType.bride),
                  value: MemberType.bride,
                },
                {
                  label: getMemberTypeTitle(MemberType.groom),
                  value: MemberType.groom,
                },
                {
                  label: getMemberTypeTitle(MemberType.other),
                  value: MemberType.other,
                },
              ]}
            />
          )}
         
          <CustomTextInput
            label='Email address'
            placeholder='Email address'
            size={200}
            value={member.email}
            onChange={(e) => {
              updateFormField('email', e.target.value);
            }}
            disabled={memberAcceptedInvite}
            error={!!emailError}
            helperText={emailError}
          />
          <div>
            {elevioArticleType && (
              <PreviewEmail
                text='Preview invite email'
                type={elevioArticleType}
              />
            )}
          </div>
          <div
            className={
              memberAcceptedInvite
                ? 'flex flex-row mt-6 justify-end'
                : 'flex flex-row mt-6 justify-between'
            }
          >
            { memberForEditing && memberForEditing.email ? (
              <Button variant='secondary' onClick={copyInviteLink}>
                {getInviteButtonText()}
              </Button>
            ) : null}
            <Button
              disabled={
                member.legal_name?.split(' ')[0].includes('TBD') ||
                (member.legal_name?.split(' ')[1] &&
                  member.legal_name?.split(' ')[1].includes('TBD')) ||
                roleNeedsUpdate
              }
              onClick={() => {
                if (memberAcceptedInvite) onClose();
                else void handleButtonClick(member);
              }}
            >
              {mainButtonText}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
