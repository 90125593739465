import { useState } from 'react';

import { getOrdained, OrdainedResponseModel } from '../api/ordained';

const useOrdained = () => {
  const [ordained, setOrdained] = useState<OrdainedResponseModel>();
  const [ordainedFetched, setOrdainedFetched] = useState<boolean>(false);

  const fetchOrdained = async () => {
    try {
      const response = await getOrdained();
      if (response.success) {
        setOrdained(response.data);
      }
      setOrdainedFetched(true);
    } catch (err) {
      setOrdainedFetched(true);
    } finally {
      setOrdainedFetched(true);
    }
  };

  return {
    ordained,
    ordainedFetched,
    fetchOrdained,
  };
};

export default useOrdained;
