import { Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Member } from '../../../api/ceremony';
import { ChecklistItemDefinition } from '../../../api/checklistItemDefinitions';
import { Speaker } from '../../../api/friends';
import { Order } from '../../../api/paywall';
import { addSpeaker } from '../../../api/speakers';
import Loader from '../../../components/Loader/loader';
import { AddGuestSpeakerModal } from '../../../components/v2/AddGuestSpeakerModal/AddGuestSpeakerModal';
import { ReferFriendModal } from '../../../components/v2/ReferFriendModal/ReferFriendModal';
import { getGroupChecklistItem } from '../../../helpers/checklistHelpers/checklistItemHelper';
import { sendCustomerIoEventHandler } from '../../../helpers/customerIoHelper';
import { getAPIErrorMessage } from '../../../helpers/helper';
import { Module } from '../../../hooks/useActiveModule';
import useLoader from '../../../hooks/useLoader';
import useOrders from '../../../hooks/useOrders';
import { useNPS } from '../../../provider/NPSProvider';
import { useChecklist } from '../../../provider/checklistProvider';
import { useDashboard } from '../../../provider/dashboardProvider';
import { useAccessibleModule } from '../../../provider/moduleAccessProvider';
import { useSnackbar } from '../../../provider/snackbarProvider';
import { FeedbackModal } from '../../Export/Modals/FeedbackModal';
import { NPSModal } from '../../Export/Modals/NPSModal';

import ChecklistGroup, {
  ChecklistGroups,
  GroupChecklistItem,
} from './v2/ChecklistGroup';

type CoupleChecklistItemsProps = {
  ceremonyId: number;
  currentUser: Member;
  couple1?: Member;
  couple2?: Member;
};

const CoupleChecklistItems = (props: CoupleChecklistItemsProps) => {
  const { ceremonyId, couple1, couple2, currentUser } = props;
  const { checklistItems, fetchChecklistItems, saveMemberChecklistItem } =
    useChecklist();

  const { setChooseCollaboratorModalOpen } = useDashboard();

  const navigate = useNavigate();
  const [guestSpeakerModalOpen, setGuestSpeakerModalOpen] = useState(false);
  const [currentSpeaker, setCurrentSpeaker] = useState<Speaker>({
    member: {} as Member,
  } as Speaker);

  const { openSnackBar } = useSnackbar();
  const { isShowing, showLoader, hideLoader } = useLoader();
  const { orders, ordersFetched, fetchOrders } = useOrders();

  const {
    hasAccessToDeclarationOfIntent,
    hasAccessToDirectionalQuestions,
    hasAccessToOfficiantRemarks,
    hasAccessToRitualsAndReadings,
    hasAccessToVowsCeremony,
    hasAccessToPronouncementCelebration,
    hasAccessToVowsAi,
    hasAccessToWelcomeToast,
    hasAccessToInviteGuests,
  } = useAccessibleModule();

  const {
    loadInvites,
    setLoadInvites,
    showNPSModal,
    showInvites,
    showFeedback,
    finishFeedback,
    setNPSUserId,
    setNPSCeremonyId,
    setNPSSection,
    userSubmittedNPS,
    fetchSubmittedNPS,
  } = useNPS();

  const handleShowNPSModal = () => {
    if (!userSubmittedNPS) {
      showNPSModal();
    }
  };

  const handleAddSpeaker = async (speaker: Speaker) => {
    setGuestSpeakerModalOpen(false);
    showLoader();
    if (ceremonyId) {
      try {
        const response = await addSpeaker(ceremonyId.toString(), speaker);
        if (response.success) {
          void fetchChecklistItems(ceremonyId, false);
          void sendCustomerIoEventHandler('Guest Invited', {
            name: speaker.member.legal_name,
            email: speaker.member.email,
            relation: speaker.member.member_sub_type,
            event: speaker.event,
            speaking_duration: speaker.alloted_time,
          });
          openSnackBar('Saved successfully');
          hideLoader();
        } else {
          hideLoader();
          openSnackBar(response.message, 'error');
        }
      } catch (err) {
        console.log(err);
        hideLoader();
        openSnackBar(getAPIErrorMessage(err), 'error');
      }
    }
  };

  const checkAccessToModule = (module: Module) => {
    switch (module) {
      case Module.DeclarationOfIntent:
        return hasAccessToDeclarationOfIntent;
      case Module.DirectionalQuestions:
        return hasAccessToDirectionalQuestions;
      case Module.OfficiantRemarks:
        return hasAccessToOfficiantRemarks;
      case Module.RitualsAndReadings:
        return hasAccessToRitualsAndReadings;
      case Module.VowsCeremony:
        return hasAccessToVowsCeremony;
      case Module.PronouncementCelebration:
        return hasAccessToPronouncementCelebration;
      case Module.VowsAi:
        return hasAccessToVowsAi;
      case Module.WelcomeToast:
        return hasAccessToWelcomeToast;
      case Module.InviteGuests:
        return hasAccessToInviteGuests;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (ceremonyId) {
      void fetchChecklistItems(ceremonyId, false);
      if (currentUser?.user_id) setNPSUserId(currentUser?.user_id);
      if (ceremonyId) setNPSCeremonyId(ceremonyId);
      setNPSSection('checklist');
      fetchSubmittedNPS();
      void fetchOrders();
    }
  }, [ceremonyId]);

  const getChecklistItemsByGroup = (
    checklistItems: ChecklistItemDefinition[],
    group: ChecklistGroups,
    orders?: Order[]
  ) => {
    const items = checklistItems
      ?.filter((item) => item.group === group)
      .sort((a, b) => a.order - b.order);
    const result = [] as GroupChecklistItem[];
    items.map((item) => {
      const mapped = getGroupChecklistItem(
        item,
        navigate,
        checkAccessToModule,
        saveMemberChecklistItem,
        setChooseCollaboratorModalOpen,
        setGuestSpeakerModalOpen,
        handleShowNPSModal,
        ceremonyId,
        currentUser,
        couple1,
        couple2,
        [],
        orders
      );
      if (mapped) {
        result.push(mapped);
      }
    });
    return result;
  };

  return (
    <>
      <Loader isShowing={isShowing} hide={hideLoader} allowHide={false} />
      <NPSModal
        onContinueHigh={showInvites}
        onContinueLow={showFeedback}
        threshold={8}
      />

      <FeedbackModal
        onCancelClick={finishFeedback}
        onContinueClick={finishFeedback}
      />
      <ReferFriendModal
        isOpen={loadInvites}
        onClose={() => setLoadInvites(false)}
        ceremonyId={ceremonyId ? Number(ceremonyId) : undefined}
      />
      <AddGuestSpeakerModal
        title='Add Guest Speaker'
        isOpen={guestSpeakerModalOpen}
        onClose={() => setGuestSpeakerModalOpen(false)}
        initialSpeaker={currentSpeaker}
        onButtonClick={handleAddSpeaker}
      />

      {checklistItems.length === 0 && (
        <div className='flex flex-col items-center justify-between mt-4 w-full'>
          {Array.from({ length: 10 }).map((_, index) => (
            <Skeleton
              className='mt-2 w-full rounded-lg'
              variant='rectangular'
              height={82}
              width={160}
              key={index}
            />
          ))}
        </div>
      )}
      {checklistItems && checklistItems.length > 0 && ordersFetched && (
        <>
          <ChecklistGroup
            title='3 months before wedding'
            checklistItems={getChecklistItemsByGroup(
              checklistItems,
              ChecklistGroups.THREE_MONTHS_BEFORE,
              orders
            )}
          />
          <ChecklistGroup
            title='2 months before wedding'
            checklistItems={getChecklistItemsByGroup(
              checklistItems,
              ChecklistGroups.TWO_MONTHS_BEFORE
            )}
          />
          <ChecklistGroup
            title='1 month before wedding'
            checklistItems={getChecklistItemsByGroup(
              checklistItems,
              ChecklistGroups.ONE_MONTH_BEFORE
            )}
          />
          <ChecklistGroup
            title='1 week before wedding'
            checklistItems={getChecklistItemsByGroup(
              checklistItems,
              ChecklistGroups.ONE_WEEK_BEFORE
            )}
          />
          <ChecklistGroup
            title='After the wedding'
            checklistItems={getChecklistItemsByGroup(
              checklistItems,
              ChecklistGroups.AFTER_THE_WEDDING
            )}
          />
          <ChecklistGroup
            title='Congratulations! Your wedding journey with Provenance is complete'
            checklistItems={[]}
          />
        </>
      )}
    </>
  );
};

export default CoupleChecklistItems;
