import { Steps } from 'intro.js-react';
import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GenericResponse, Member, MemberType } from '../../../api/ceremony';
import { Speaker, removeSpeaker } from '../../../api/friends';
import {
  OutputResponse,
  downloadOutput,
  getOutputData,
  outputModules,
} from '../../../api/output';
import {
  sendOutputReminder,
  sendSpeechReminder,
  sendWeddingApproachingReminder,
} from '../../../api/planner';
import { addSpeaker } from '../../../api/speakers';
import { updateUserCustomField } from '../../../api/user';
import { activateCeremony } from '../../../api/weddingPlanner';
import Button from '../../../components/v2/Button/Button';
import ButtonPill from '../../../components/v2/ButtonPill';
import InfoIcon from '../../../components/v2/InfoIcon/InfoIcon';
import { Typography } from '../../../components/v2/Typography/Typography';
import {
  getCoupleName,
  getVowsTitle,
  getMemberName,
} from '../../../helpers/ceremonyHelper';
import {
  getAPIErrorMessage,
  getCookie,
  setCookie,
} from '../../../helpers/helper';
import useLoader from '../../../hooks/useLoader';
import useVows from '../../../hooks/useVows';
import { useDashboard } from '../../../provider/dashboardProvider';
import { usePlanner } from '../../../provider/plannerProvider';
import { useAllQuestions } from '../../../provider/questionsProvider';

import './PlannerDashboard.scss';
import PlannerItem, { deactivatedButtonContent } from './PlannerItem';

import { AddGuestSpeakerModal } from '../../../components/v2/AddGuestSpeakerModal/AddGuestSpeakerModal';
import { useSnackbar } from '../../../provider/snackbarProvider';

import ReactivateCeremonyModal from './ReactivaCeremonyModal';

import { getTextDurationInMinutes } from '../../../helpers/textHelper';

import { introJsSteps } from './introJSStepsWPDashboard';

import { useAuth } from '../../../provider/authProvider';
import TourModal from '../CeremonyChecklist/TourModal';
import useSpeechData from '../../../hooks/useSpeechData';
import { useSpeakers } from '../../../hooks/useSpeakers';

import { AddCeremonyModal } from './AddCeremonyModal';

import useUser from '../../../hooks/useUser';
import { ChecklistItemIdentifier } from '../../../helpers/checklistHelpers/identifiers';
import { useChecklist } from '../../../provider/checklistProvider';
import arrowLeftSvg from '../../../assets/images/icons/arrow-left-long.svg';
import paintBrush from '../../../assets/images/icons/paint-brush-purple.svg';
import heartInSpeechIcon from '../../../assets/images/icons/heart-in-speech-icon-purple.svg'
import { useWindowSize } from '../../../hooks/useWindowSize';
import { Separator } from '../../../components/v2/Separator/Separator';
import EmptyState from '../../../components/v2/EmptyState/EmptyState';
import { DraggableToastList } from '../../../components/v2/DraggableToastList/DraggableToastList';

export const PlannerDashboard = () => {
  const { openSnackBar } = useSnackbar();
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const [addGuestSpeakerModalOpen, setAddGuestSpeakerModalOpen] =
    useState(false);
  const [ceremonyScript, setCeremonyScript] = useState('');

  const [showTourWP, setShowTourWP] = useState<boolean>(false);
  const [showTourWPModal, setShowTourWPModal] = useState<boolean>(false);
  const hasCompletedTourKey = 'wp-dashboard-tour-done';

  const { user, getProfile } = useAuth();

  const {saveMemberChecklistItemUsingIdentifier} = useChecklist();

  const {
    setShowReactivateModal,
    weddingPlannerStatus,
    fetchWeddingPlannerStatus,
    showPaymentModal,
  } = usePlanner();

  const {
    getVows,
    vowsCouple1TargetLength,
    vowsCouple2TargetLength,
    vowsCouple1Duration,
    vowsCouple2Duration,
  } = useVows();

  const {
    ritualMinutes,
    fetchAllQuestions,
  } = useAllQuestions();
  const {
    ceremony,
    couple1,
    couple2,
    currentRole,
    currentUser,
    fetchCeremony,
  } = useDashboard();

  const { speechData } = useSpeechData();

  const { fetchSpeakers, speakerEvents } = useSpeakers({
    ceremonyId: ceremony?.id || 0,
  });

  const { fetchCeremonies } = useUser();
  const { isMobile } = useWindowSize();

  if (currentRole !== MemberType.weddingPlanner && currentRole !== undefined) {
    navigate(`/ceremony/${ceremony?.id.toString() || ''}`);
  }

  const getOutput = async (ceremonyId: string) => {
    try {
      const outputResponse: OutputResponse = await getOutputData(ceremonyId);
      if (outputResponse.success) {
        let res = '';
        [
          outputModules.welcome,
          outputModules['officiant-address'],
          outputModules['readings-rituals'],
          outputModules.vows,
          outputModules.declaration,
          outputModules.pronouncement,
        ].forEach((m) => {
          const data = outputResponse.data.filter((d) => d.module === m);
          if (data && data.length > 0) {
            res += data[0].content;
          }
        });
        setCeremonyScript(res);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (ceremony) {
      fetchCeremony(ceremony?.id.toString());
    }
    if (!weddingPlannerStatus) {
      fetchWeddingPlannerStatus();
    }
  }, []);

  useEffect(() => {
    if (ceremony && ceremony.id) {
      void fetchAllQuestions(ceremony.id.toString());
      void getOutput(ceremony.id.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceremony]);

  useEffect(() => {
    if (ceremony && couple1 && couple2) {
      setCookie('last_active_ceremony', ceremony.id.toString(), 365);
      void getVows(ceremony.id.toString(), couple1, couple2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ceremony, couple1, couple2]);

  useEffect(() => {
    if (
      user &&
      user.custom_fields.filter(
        (f) => f.key === hasCompletedTourKey && f.value === 'true'
      ).length === 0
    ) {
      const cookie = getCookie(hasCompletedTourKey);

      !cookie && setShowTourWPModal(true);
    }
  }, [user]);

  const onSendWeddingApproachingReminder = async (memberId: number) => {
    if (ceremony) {
      try {
        const reminderResponse: GenericResponse =
          await sendWeddingApproachingReminder(memberId, ceremony.id);
        if (reminderResponse.success) {
          openSnackBar('Reminder email sent successfully');
        }
      } catch (err) {
        openSnackBar('Something went wrong in sending reminder', 'error');
      }
    }
  };

  const onSendOutputReminder = async () => {
    if (ceremony) {
      try {
        const reminderResponse: GenericResponse = await sendOutputReminder(
          ceremony.id
        );
        if (reminderResponse.success) {
          openSnackBar('Reminder email sent successfully');
        }
      } catch (err) {
        openSnackBar('Something went wrong in sending reminder', 'error');
      }
    }
  };

  const onExportCeremonyOutputPDF = async () => {
    if (ceremony) {
      showLoader();
      const blob = await downloadOutput(
        ceremony.id,
        'output',
        true,
        true,
        ritualMinutes
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'output';
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      hideLoader();
    }
  };

  const onExportVowsOutputPDF = async (
    member?: Member,
    currentUser?: Member
  ) => {
    if (ceremony && member && currentUser) {
      showLoader();
      const blob = await downloadOutput(
        ceremony.id,
        'vows',
        true,
        true,
        ritualMinutes,
        member?.id
      );
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = getVowsTitle(member, currentUser);
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove();
      hideLoader();
    }
  };

  const handleAddSpeaker = async (speaker: Speaker) => {
    if (!ceremony) return;
    setAddGuestSpeakerModalOpen(false);
    try {
      const response = await addSpeaker(ceremony.id.toString(), speaker);
      if (response.success) {
        openSnackBar('Saved successfully');
        hideLoader();
        void fetchSpeakers();

        await saveMemberChecklistItemUsingIdentifier(
          {
            completed: true,
          },
          ChecklistItemIdentifier.INVITE_TOAST_GIVERS,
          true
        );

      } else {
        hideLoader();
        openSnackBar(response.message, 'error');
      }
    } catch (err) {
      console.log(err);
      hideLoader();
      openSnackBar(getAPIErrorMessage(err), 'error');
    }
  };

  const handleDeleteSpeaker = async (speaker: Speaker) => {
    if (!ceremony) return;
    try {
      const response = await removeSpeaker({ speaker_id: speaker.id });
      if (response.success) {
        openSnackBar('Saved successfully');
        hideLoader();
        
        void fetchSpeakers();
      } else {
        hideLoader();
        openSnackBar(response.message, 'error');
      }
    } catch (err) {
      console.log(err);
      hideLoader();
      openSnackBar(getAPIErrorMessage(err), 'error');
    }
  };

  const handleDoneTourClick = async () => {
    setShowTourWP(false);
    if (
      user &&
      user.custom_fields.filter(
        (f) => f.key === hasCompletedTourKey && f.value === 'true'
      ).length === 0
    ) {
      try {
        await updateUserCustomField(hasCompletedTourKey, 'true');
        void getProfile(() => {});
        setCookie(hasCompletedTourKey, 'true', 2);
      } catch (error) {}
    }
  };

  if (!ceremony) {
    return null;
  }

  let ceremonyInfoMessage: ReactElement = (
    <Typography type='body-400'>
      Depending on the privacy settings the couple has selected, you could have
      preview and/or edit access to their ceremony script, vows, and toast(s)
      below.
    </Typography>
  );

  if (isMobile) {
    ceremonyInfoMessage = <></>
  }

  if (ceremony.isCeremonyDeactivatedByPlanner) {
    ceremonyInfoMessage = (
      <div className='flex bg-neutral-300 p-3 rounded'>
        <InfoIcon className='flex align-center' />
        <div className='flex justify-between flex-wrap flex-1'>
          <Typography
            type='body-200'
            className='mr-2 my-auto'
            variant='functional-low'
          >
            You have deactivated this wedding. To regain access to the scripts
            and guest list please reactivate this wedding.
          </Typography>
          <div>
            <Button
              className='mr-3'
              size='100'
              onClick={() => {
                window.open('https://provenance.elevio.help/en/articles/63-how-does-provenance-pro-work', '_blank');
              }}
              variant='text'
            >
              Learn more
            </Button>
            <Button
              className='ml-3'
              size='100'
              onClick={() => {
                void setShowReactivateModal(true);
              }}
              variant='text'
            >
              Reactivate
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='PlannerDashboard PlannerDashboard-root'>
      <div className='PlannerDashboard-section flex flex-col gap-8 introJSWP_dashboard_step3'>
        <div
          className='mb-2 flex cursor-pointer items-center'
          onClick={() => navigate( `/ceremony/planner/client-list`)}
        >
          <img alt='Go to dashboard' src={arrowLeftSvg} className='mr-2' />
          <Button variant='text' size='100' >
            Back to Client List
          </Button>
        </div>
        <div className='PlannerDashboard-section__header'>
          <Typography type='display-400'>Client Dashboard</Typography>
          {ceremonyInfoMessage}
        </div>

        <div className='PlannerDashboard-section__content grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 sm:gap-6'>
          {couple1 && couple2 && (
            <PlannerItem
              title='Ceremony Script'
              icon={paintBrush}
              time={getTextDurationInMinutes(ceremonyScript) + ritualMinutes}
              progress={speechData?.ceremony_status || 0}
              allowExport={true}
              onSendWeddingReminder={onSendOutputReminder}
              onExportPDF={onExportCeremonyOutputPDF}
              url={`/preview-script/${ceremony.id}`}
              name={getCoupleName(couple1, couple2)}
              isDeactivated={ceremony.isCeremonyDeactivatedByPlanner}
            />
          )}
          {couple1 && currentUser && (
            <PlannerItem
              title={getVowsTitle(couple1, currentUser)}
              icon={heartInSpeechIcon}
              time={vowsCouple1Duration}
              targetTime={vowsCouple1TargetLength}
              showTime={couple1.share_vows_time_with_planner || false}
              progress={speechData?.vows_status_couple1 || 0}
              allowExport={couple1.share_vows_content_with_planner || false}
              onExportPDF={
                couple1.share_vows_content_with_planner
                  ? () => onExportVowsOutputPDF(couple1)
                  : undefined
              }
              url={`/preview-vows/${ceremony.id}/${couple1.id || ''}`}
              name={getMemberName(couple1)}
              pendingInvitation={!couple1.user_id}
              isPrivate={!couple1.share_vows_content_with_planner || false}
              isDeactivated={ceremony.isCeremonyDeactivatedByPlanner}
              onSendWeddingReminder={() => {
                couple1?.id &&
                  void onSendWeddingApproachingReminder(couple1.id);
              }}
            />
          )}
          {couple2 && currentUser && (
            <PlannerItem
              title={getVowsTitle(couple2, currentUser)}
              icon={heartInSpeechIcon}
              time={vowsCouple2Duration}
              targetTime={vowsCouple2TargetLength}
              showTime={couple2.share_vows_time_with_planner || false}
              progress={speechData?.vows_status_couple2 || 0}
              allowExport={couple2.share_vows_content_with_planner || false}
              onExportPDF={
                couple2.share_vows_content_with_planner
                  ? () => onExportVowsOutputPDF(couple2)
                  : undefined
              } 
              url={`/preview-vows/${ceremony.id}/${couple2.id || ''}`}
              name={getMemberName(couple2)}
              pendingInvitation={!couple2.user_id}
              isPrivate={!couple2.share_vows_content_with_planner || false}
              isDeactivated={ceremony.isCeremonyDeactivatedByPlanner}
              onSendWeddingReminder={() => {
                couple2?.id &&
                  void onSendWeddingApproachingReminder(couple2.id);
              }}
            />
          )}
        </div>
      </div>

      <div>
        {
          speakerEvents.length === 0 ? 
          (
          <div className='mt-10 flex flex-col gap-4'>
            <Typography type='display-400'>
              Toasts
            </Typography>
    
            <EmptyState
              title='No speakers added yet'
              text='Add a guest speaker to the engagement party, rehearsal dinner, or reception.'
              buttonText='Add Guest Speaker'
              onClick= { () => setAddGuestSpeakerModalOpen(true) }
              background='grey'
            />

            <AddGuestSpeakerModal
              title='Add Guest Speaker'
              isOpen={addGuestSpeakerModalOpen}
              onClose={() => setAddGuestSpeakerModalOpen(false)}
              initialSpeaker={{member:{} as Member} as Speaker}
              onButtonClick={handleAddSpeaker}
            />
          </div>
          ) : 
          <>
            <DraggableToastList
              ceremonyId={ceremony.id}
              title='Toasts'
              addSpeakerBlocked={ceremony.isCeremonyDeactivatedByPlanner}
            />
          </>
        }
      </div>

      {weddingPlannerStatus && (
        <ReactivateCeremonyModal
          ceremony={ceremony}
          couple1={couple1}
          couple2={couple2}
          activeWeddings={weddingPlannerStatus.activatedWeddings}
          totalWeddings={weddingPlannerStatus.maxWeddings}
        />
      )}

      <Steps
        enabled={showTourWP}
        steps={introJsSteps}
        initialStep={0}
        onExit={() => {
          void handleDoneTourClick();
        }}
        options={{ doneButton: 'Done' }}
      />
      <TourModal
        title="Welcome to Provenance's Ceremony Dashboard."
        text="Here is where you'll be able to monitor your client's progress, check the length of every speech, and print extra copies for the big day."
        isOpen={showTourWPModal && !showPaymentModal}
        onClose={() => {
          setShowTourWPModal(false);
          void handleDoneTourClick();
        }}
        onStartTourClick={() => {
          setShowTourWPModal(false);
          setShowTourWP(true);
        }}
      />
      {currentUser?.user_id && showTourWPModal && process.env.REACT_APP_ENV === 'production' && (
        <img
          id='_SHRSL_img_1'
          src={`https://www.shareasale.com/sale.cfm?tracking=${currentUser.user_id}&amount=0.00&merchantID=135962&transtype=lead`}
          width='1'
          height='1'
        ></img>
      )}

      {weddingPlannerStatus && (
        <AddCeremonyModal
          weddingPlannerStatus={weddingPlannerStatus}
          refetch={() => {
            void fetchCeremonies();
            void fetchWeddingPlannerStatus();
          }}
        />
      )}
    </div>
  );
};
